export enum SearchParams {
  vehicleId = "vN",
  searchQuery = "q",
  location = "location",
  sortBy = "sortBy",
  preview = "preview",
  manualPanningState = "manualPanningState",
  triggerValidation = "triggerValidation",
  date = "date",
  totalRuntime = "totalRuntime",
  page = "page",
  requestId = "requestId",
  utmCampaign = "utm_campaign",
  utmMedium = "utm_medium",
  utmSource = "utm_source",
  email = "email",
  pageTab = "tab",
  previewMode = "previewMode",
}
